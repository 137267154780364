import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import nosotrosWallet from "../../assets/icons/nuestro_wallet_logo.webp";
import menu from "../../assets/images/menu.png";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

const NavBarEn = () => {

    const [isMobile, setIsMobile] = useState(false);
  
    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
      };
  
      // Initial check on mount
      handleResize();
  
      // Event listener for window resize
      window.addEventListener("resize", handleResize);
  
      // Cleanup the event listener on component unmount
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);
  return (
    <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand href={isMobile ? "/mobile" : "/home"}>
          <img src={nosotrosWallet} width="200" className="mt-2"/>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav">
          <img src={menu} width="40"/>
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav" className="mx-auto">
          <Nav className="mx-auto">
            <Nav.Link
              style={{
                fontFamily: "AvenirBold",
                color: "rgb(33, 67, 132)",
                fontSize: "14px",
                fontWeight: "500",
                padding: "13px 15px",
              }}
              onMouseOver={(e) => (e.target.style.color = "rgb(232, 0, 120)")}
              onMouseOut={(e) => (e.target.style.color = "rgb(33, 67, 132)")}
              href="/home"
            >
              Home
            </Nav.Link>
            <Nav.Link
              style={{
                fontFamily: "AvenirBold",
                color: "rgb(33, 67, 132)",
                fontSize: "14px",
                fontWeight: "500",
                padding: "13px 15px",
              }}
              onMouseOver={(e) => (e.target.style.color = "rgb(232, 0, 120)")}
              onMouseOut={(e) => (e.target.style.color = "rgb(33, 67, 132)")}
              href="/aboutus"
            >
              About Us
            </Nav.Link>
            <Nav.Link
              style={{
                fontFamily: "AvenirBold",
                color: "rgb(33, 67, 132)",
                fontSize: "14px",
                fontWeight: "500",
                padding: "13px 15px",
              }}
              onMouseOver={(e) => (e.target.style.color = "rgb(232, 0, 120)")}
              onMouseOut={(e) => (e.target.style.color = "rgb(33, 67, 132)")}
              href="/product"
            >
              Products
            </Nav.Link>
            <Nav.Link
              style={{
                fontFamily: "AvenirBold",
                color: "rgb(33, 67, 132)",
                fontSize: "14px",
                fontWeight: "500",
                padding: "13px 15px",
              }}
              onMouseOver={(e) => (e.target.style.color = "rgb(232, 0, 120)")}
              onMouseOut={(e) => (e.target.style.color = "rgb(33, 67, 132)")}
              href="https://nuestrofinancialllc.freshdesk.com/support/home"
              target="_blank"
            >
              Help & Support
            </Nav.Link>
            <Nav.Link
              style={{
                fontFamily: "AvenirBold",
                color: "rgb(33, 67, 132)",
                fontSize: "14px",
                fontWeight: "500",
                padding: "13px 15px",
              }}
              onMouseOver={(e) => (e.target.style.color = "rgb(232, 0, 120)")}
              onMouseOut={(e) => (e.target.style.color = "rgb(33, 67, 132)")}
              href="/legal"
            >
              Legal
            </Nav.Link>
            <Nav.Link
              style={{
                fontFamily: "AvenirBold",
                color: "rgb(33, 67, 132)",
                fontSize: "14px",
                fontWeight: "500",
                padding: "13px 15px",
              }}
              onMouseOver={(e) => (e.target.style.color = "rgb(232, 0, 120)")}
              onMouseOut={(e) => (e.target.style.color = "rgb(33, 67, 132)")}
              href="/contactus"
            >
              Contact Us
            </Nav.Link>
            {/* <Nav.Link
              style={{
                fontFamily: "AvenirBold",
                color: "rgb(33, 67, 132)",
                fontSize: "14px",
                fontWeight: "500",
                padding: "13px 15px",
              }}
              onMouseOver={(e) => (e.target.style.color = "rgb(232, 0, 120)")}
              onMouseOut={(e) => (e.target.style.color = "rgb(33, 67, 132)")}
              href={isMobile ? "/es/mobile" : "/es/home"}
            >
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAALCAMAAABBPP0LAAAAflBMVEUAXgnp6ejj49zOAADGAACBvZl+u5Z2t4/9/f36+vrnZ2tmrYNdqXv29vbjR03fQkjjWFy4AABVpHba0LzfOD5PoXHg1sXHsIyDXjzSxq8AUQCrtaONfWn08fHbLTPgTVBInWudrZk7lmHYIymvAAAAQwAANwAAJQDU1NTKxMQZpDQjAAAAaklEQVR4ATXItUEEUAAFsLxvuDc4+2+Fu0ODnFSXMgGBRIguWQS/0YdMZqJFkiIyWFttkk4jqs1kfHcohzG059YetlndUhh1zYF+ZnUVhbpW3A4X6O97be1jDevP1nRoPGUAz9ByFAJZgjmA3Q34KhcIQAAAAABJRU5ErkJggg=="
                alt="Español"
                style={{ width: "16px", height: "10px" }}
              />{" "}
              Español
            </Nav.Link> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBarEn;
