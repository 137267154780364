import React from 'react'
import "../../styles/main.css";
import "../../styles/responsive.css";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../../node_modules/bootstrap/dist/js/bootstrap.bundle.min";
import legal_docs from "../../assets/documents/Privacy_Policy_Nuestro.pdf"
import Footer from '../../components/footer/english';
import NavBar from '../../components/navbar/english';


const Legal_Four = () => {
  return (
    <div>
        <NavBar/>
        <section className="main-slider pt-5" style={{backgroundColor: '#014384', color:'#ffffff', padding:'0 8%', textAlign: 'justify'}}>
        
        <h1 style={{fontFamily: 'RockoUltraFLF'}} className="text-center">Privacy Policy</h1><br/><br/>
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>

  <p style={{fontFamily: 'Avenir', fontSize: '15px', color: '#ffffff', margin: '0'}}>Effective Date 11/1/23</p>

  <a href={legal_docs} 
     style={{textAlign: 'right', fontSize: '15px', color: '#ffffff', textDecoration: 'none', border:'2px solid rgb(232, 0, 120)', borderRadius:'20px', backgroundColor:'rgb(232, 0, 120)', padding:'8px 15px'}} 
     download="Privacy_Policy_Nuestro.pdf">
    Download pdf
  </a>

</div>
        
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>Nuestro Wallet and its affiliates (collectively “Nuestro Wallet","we”, "our," or "us") are committed to protecting your privacy. This Privacy Policy explains how your personal information is collected, used and disclosed by Nuestro Wallet. This Privacy Policy applies to services provided through Nuestro Wallet platform. By accessing or using our Services, or otherwise manifesting your assent to this Privacy Policy, you signify that you have read, understood and agree to our collection, storage, use and disclosure of your personal information as described in this Privacy Policy and our Terms of Use. If you are an individual acting on behalf of a Company, you represent and warrant that you are an authorized representative of Company with the authority to bind Company to this Privacy Policy ("Administrator"), and that you agree to this Privacy Policy on Company’s behalf.</p>
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>Terms not defined in this Privacy Policy will have the meaning set forth in our Terms of Use.</p>

        <h4 style={{padding:'1% 0'}}>1. What Information Do We Collect and for What Purpose?</h4>
        <h6 style={{padding:'1% 0'}}>The categories of information we collect can include:</h6>
        <ul style={{padding:'0 4%'}}>
            <li style={{padding:'0.5% 0', fontSize: '18px', fontFamily: 'Avenir', listStyleType: 'disc'}}>Information you provide to us directly. We will collect personal, business and financial information as a prerequisite to you being authorized to use the Services to open an account. The collection of information may include: 
                <ul style={{padding:'0 6%'}}>
                    <li style={{padding:'0.5% 0', fontSize: '18px', fontFamily: 'Avenir', listStyleType:'circle'}}>Name</li>
                    <li style={{padding:'0.5% 0', fontSize: '18px', fontFamily: 'Avenir', listStyleType:'circle'}}>Personal address</li>
                    <li style={{padding:'0.5% 0', fontSize: '18px', fontFamily: 'Avenir', listStyleType:'circle'}}>Date of birth</li>
                    <li style={{padding:'0.5% 0', fontSize: '18px', fontFamily: 'Avenir', listStyleType:'circle'}}>Social Security number(SSN)</li>
                    <li style={{padding:'0.5% 0', fontSize: '18px', fontFamily: 'Avenir', listStyleType:'circle'}}>Phone number</li>
                    <li style={{padding:'0.5% 0', fontSize: '18px', fontFamily: 'Avenir', listStyleType:'circle'}}>A photo of their passport or driver's license or government issued identification</li>
                    <li style={{padding:'0.5% 0', fontSize: '18px', fontFamily: 'Avenir', listStyleType:'circle'}}>Occupation</li>
                    <li style={{padding:'0.5% 0', fontSize: '18px', fontFamily: 'Avenir', listStyleType:'circle'}}>Business name</li>
                    <li style={{padding:'0.5% 0', fontSize: '18px', fontFamily: 'Avenir', listStyleType:'circle'}}>tex identification number</li>
                    <li style={{padding:'0.5% 0', fontSize: '18px', fontFamily: 'Avenir', listStyleType:'circle'}}>Business address</li>
                    <li style={{padding:'0.5% 0', fontSize: '18px', fontFamily: 'Avenir', listStyleType:'circle'}}>Business telephone number</li>
                    <li style={{padding:'0.5% 0', fontSize: '18px', fontFamily: 'Avenir', listStyleType:'circle'}}>Business bank account information</li>
                    <li style={{padding:'0.5% 0', fontSize: '18px', fontFamily: 'Avenir', listStyleType:'circle'}}>Business related documentation</li>
                    <li style={{padding:'0.5% 0', fontSize: '18px', fontFamily: 'Avenir', listStyleType:'circle'}}>Telephone number</li>
                    <li style={{padding:'0.5% 0', fontSize: '18px', fontFamily: 'Avenir', listStyleType:'circle'}}>Email address</li>
                </ul>
            </li>
            <li style={{padding:'0.5% 0', fontSize: '18px', fontFamily: 'Avenir', listStyleType: 'disc'}}>Additional information will be requested at account opening and may be requested throughout the life of the account relationship to meet regulatory and legal requirements. If you choose to provide us with personal information, business information, financial information, and financial data, you consent to the transfer and storage of that information on our servers located within the United States. We may also collect any communications between you and Nuestro Wallet and any other information you provide to Nuestro Wallet.</li>
            <li style={{padding:'0.5% 0', fontSize: '18px', fontFamily: 'Avenir', listStyleType: 'disc'}}><b>Data collected through the use of the Service.</b> We collect information about how you use the Service, your actions on the Service, and your communications with us through the Services. If you need to update your permissions, you can do so in the "Settings" app of your device. Please remember that Nuestro Wallet may, but has no obligation to, monitor, record, and store information you provide to Nuestro Wallet and information we collect about you in order to protect your safety or the safety of other users, to assist with regulatory or law enforcement efforts, or to protect and defend our rights and property. By using the Service, you consent to the recording, storage and disclosure of such communications you send or receive for these purposes.</li>
            <li style={{padding:'0.5% 0', fontSize: '18px', fontFamily: 'Avenir', listStyleType: 'disc'}}><b>USA Patriot Act.</b> To help the government fight the funding of terrorism and money laundering activities, the USA PATRIOT Act, a Federal law, requires financial institutions to obtain, verify, and record information that identifies each business that opens an account and the personal information of its owners or representatives. Therefore, when applying for an account, we, on behalf of financial institution(s), will ask for details about the personally identifiable information about the business owners, administrators, and/or authorized users such as: name, address, date of birth, SSN, phone number, and other information that will allow financial institution(s) to confirm your identity. We may also ask for details about the business owner’s, administrators’, and/or authorized user’s driver’s license number, passport information, or other identifying information. We may also collect photos, text, videos, or any other information you upload and/or send through our Services.</li>
            <li style={{padding:'0.5% 0', fontSize: '18px', fontFamily: 'Avenir', listStyleType: 'disc'}}><b>Information Collected From Your Device.</b> When you use our Services, we may collect information from your computer or mobile device, such as the unique identification number, the model, operating system and version, your browser type and version, the presence of any device, operating system, or browser add-ons, the times you access our Services, the locations from where you access our Services (attributed based on your IP address, the page you visited before navigating to our Services, and your mobile device’s location services), and your stored contacts.</li>
        </ul>
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>We use this information to operate, maintain, and provide to you the features and functionality of the Service, as well as to communicate directly with you, such as to send you email messages and push notifications. We may also send you Service-related emails or messages (e.g., account verification, change or updates to features of the Service, technical and security notices). For more information about your communication preferences, see "Control Over Your Information" below.</p>
        <h4 style={{padding:'1% 0'}}>2. How We Use Cookies and Other Tracking Technology to Collect Information?</h4>


        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>We, and our third-party partners, automatically collect certain types of usage information when you visit our Services, read our emails, or otherwise engage with us. We typically collect this information through a variety of tracking technologies, including cookies, Flash objects, web beacons, file information and similar technology (collectively, "tracking technologies"). "Cookies" are small packets of data that a website stores on your computer’s or mobile device’s hard drive (or other storage medium) so that your computer will "remember" information about your use. We use both first- and third-party session cookies and persistent cookies. Session cookies make it easier for you to navigate our Services and expire when you close the Services. Persistent cookies remain on your device for an extended period of time or until you delete them. Persistent cookies enable us to track and target the interests of our visitors to personalize the experience on our Services.</p>
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>Through the tracking technologies we collect information about your device and its software, such as your IP address, browser type, Internet service provider, platform type, device type, operating system, date and time stamp, a unique ID that allows us to uniquely identify your browser, mobile device or your account, and other such information. We also collect information about the way you use our Services, for example, the site from which you came and the site to which you are going when you leave our website, the pages you visit, the links you click, how frequently you access the Services, whether you open emails or click the links contained in emails, whether you access the Services from multiple devices, and other actions you take on the Services. When you access our Services from a mobile device, we may collect unique identification numbers associated with your device or our mobile application (including, for example, a UDID, Unique ID for Advertisers ("IDFA"), Google AdID, or Windows Advertising ID), mobile carrier, device type, model and manufacturer, mobile device operating system brand and model, phone number, and depending on your mobile device settings, your geographical location data, including GPS coordinates (e.g., latitude and/or longitude) or similar information regarding the location of your mobile device, or we may be able to approximate a device’s location by analyzing other information, like an IP address.</p>
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>We may use one or more third–party analytics services to evaluate your use of the Services, as the case may be, by compiling reports on activity (based on their collection of IP addresses, Internet service provider, browser type, operating system and language, referring and exit pages and URLs, data and time, amount of time spent on particular pages, what sections of the Website you visit, number of links clicked, search terms and other similar usage data) and analyzing performance metrics. These third parties use cookies and other technologies to help collect, analyze, and provide us reports or other data.</p>
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>By accessing and using the Services, you consent to the processing of data about you by these analytics providers in the manner and for the purposes set out in this Privacy Policy. Please be advised that if you opt out of any service, you may not be able to use the full functionality of the Services. </p>
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>Although we do our best to honor the privacy preferences of our users, we are unable to respond to Do Not Track signals set by your browser at this time.</p>
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>We use or may use the data collected through tracking technologies to: (a) remember information so that you will not have to re-enter it during your visit or the next time you visit the site; (b) provide custom, personalized content and information, including targeted content and advertising; (c) identify you across multiple devices; (d) provide and monitor the effectiveness of our Service; (e) monitor aggregate metrics such as total number of visitors, traffic, usage, and demographic patterns on our Services; (f) diagnose or fix technology problems; and (g) otherwise to plan for and enhance our Services.</p>
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>If you would prefer not to accept cookies, most browsers will allow you to: (i) change your browser settings to notify you when you receive a cookie, which lets you choose whether or not to accept it; (ii) disable existing cookies; or (iii) set your browser to automatically reject cookies. Please note that doing so may negatively impact your experience using the Services, as some features and services on our Services may not work properly. Depending on your mobile device and operating system, you may not be able to delete or block all cookies. You may also set your email options to prevent the automatic downloading of images that may contain technologies that would allow us to know whether you have accessed our email and performed certain functions with it. Note that deleting cookies does not delete Local Storage Objects (LSOs) such as Flash objects and HTML5. You can learn more about Flash objects - including how to manage privacy and storage settings for Flash cookies – on Adobe’s website. If you choose to delete Flash objects from our sites, then you may not be able to access and use all or part of the Services or benefit from the information and services offered.</p>
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>We and our third-party partners may also use cookies and tracking technologies for advertising purposes. For more information about tracking technologies, please see "Third-Party Tracking and Online Advertising" below.</p>

        <h4 style={{padding:'1% 0'}}>3. Sharing of Your Information</h4>
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>We may share your personal information in the instances described below. For further information on your choices regarding your information, see the "Control Over Your Information" section below.<br/><br/>We may share your personal information with:</p>
        <ul style={{padding:'0 4%'}}>
            <li style={{padding:'0.5% 0', fontSize: '18px', fontFamily: 'Avenir', listStyleType: 'disc'}}>Third parties at your request.</li>
            <li style={{padding:'0.5% 0', fontSize: '18px', fontFamily: 'Avenir', listStyleType: 'disc'}}>Third-party vendors and other service providers that perform services on our behalf, as needed to carry out their work for us, which may include providing the following services: identifying and serving targeted advertisements, tax and accounting, web hosting, customer and support services, or analytic services;</li>
            <li style={{padding:'0.5% 0', fontSize: '18px', fontFamily: 'Avenir', listStyleType: 'disc'}}>Third parties to investigate fraud inside or outside our Services;</li>
            <li style={{padding:'0.5% 0', fontSize: '18px', fontFamily: 'Avenir', listStyleType: 'disc'}}>Other parties in connection with a company transaction, such as a merger, sale of company assets or shares, reorganization, financing, change of control or acquisition of all or a portion of our business by another company or third-party, or in the event of a bankruptcy or related or similar proceedings; and</li>
            <li style={{padding:'0.5% 0', fontSize: '18px', fontFamily: 'Avenir', listStyleType: 'disc'}}>Third parties as required by law or subpoena or if we reasonably believe that such action is necessary to (a) comply with the law and the reasonable requests of law enforcement; (b) to enforce our Terms of Service (US) or to protect the security or integrity of our Service; and/or (c) to exercise or protect the rights, property, or personal safety of “Nuestro Wallet", our visitors, or others.</li>
        </ul>

        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>We may also share information with others in an aggregated or otherwise anonymized form that does not reasonably identify you directly as an individual.</p>
        <h4 style={{padding:'1% 0'}}>4. Control Over Your Information</h4>
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>Profile and Data Sharing Settings. You may update your profile information, such as your username and profile photo, and may change some of your data sharing preferences on your Settings Page.</p>
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>The app will ask for access to your Device Information. You may control the app’s access to your device information through your "Settings" app on your device. For instance, you can withdraw permission for the app to access your address book, location, photo stream and camera.</p>
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>How to control your communications preferences: You can stop receiving promotional email communications from us by clicking on the "unsubscribe link" provided in such communications. We make every effort to promptly process all unsubscribe requests. You may not opt out of service-related communications (e.g., account verification, transactional communications, changes/updates to features of the Service, technical and security notices).</p>
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>Modifying or deleting your information: If you have any questions about reviewing, modifying, or deleting your information, or if you want to remove your name or comments from our website or publicly displayed content, you can contact us by emailing us, calling us, live chat or submitting a Customer Support Form online. Please visit Nuestro Wallet’s Contact Us section of the website for the information. We may not be able to modify or delete your information in all circumstances.</p>
        <h4 style={{padding:'1% 0'}}>5. Site Data</h4>
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>In an ongoing effort to better understand our users and the Services, we might analyze Site Data to operate, maintain, manage, and improve the Services. This Site Data does not identify you personally. We may share this aggregate data with our affiliates, agents, and business partners. We may also disclose Site Data and aggregate user statistics to describe the Services to current and prospective business partners and to other third parties for other lawful purposes.</p>
        <h4 style={{padding:'1% 0'}}>6. Third-Party Tracking and Online Advertising</h4>
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>We may share, or we may permit third-party online advertising networks, social media companies and other third-party services, to collect, information about your use of our website over time so that they may play or display ads that may be relevant to your interests on our Services as well as on other websites or apps, or on other devices you may use. Typically, though not always, the information we share is provided through cookies or similar tracking technologies, which recognize the device you are using and collect information, including hashed data, clickstream information, browser type, time and date you visited the Services, and other information. This information is used to display targeted ads on or through our Services or on other websites or apps. We or the online advertising networks use this information to make the advertisements you see online more relevant to your interests. As noted above, depending on your browser or mobile device, you may be able to set your browser to delete or notify you of cookies and other tracking technology by actively managing the settings on your browser or mobile device. You may also be able to limit interest-based advertising through the settings on your mobile device by selecting "limit ad tracking" (iOS) or "opt-out of interest-based ads'' (Android). If you have any questions about opting out of the collection of cookies and other tracking/recording tools, you can contact us by writing us at our address, emailing us, live chat, calling us or submitting a Customer Support Form online. Please visit Nuestro Wallet Contact Us section of the website for the information.</p>

        <h4 style={{padding:'1% 0'}}>7. How We Store and Protect Your Information</h4>
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>Data storage and transfer: Your information collected through our Services may be stored and processed in the United States. </p>
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>Keeping your information safe: We care about the security of your information and use commercially reasonable physical, administrative, and technological safeguards to preserve the integrity and security of all information collected through our Services. However, no security system is impenetrable, and we cannot guarantee the security of our systems or the systems of the third parties with which we may share your information, nor can we guarantee that the information you supply will not be intercepted while being transmitted over the Internet. In particular, e-mail sent to us may not be secure, and you should therefore take special care in deciding what information you send to us via e-mail.</p>
        <h4 style={{padding:'1% 0'}}>8. Children’s Privacy</h4>
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>Nuestro Wallet does not knowingly collect or solicit any information from anyone under the age of 18 through our Services. If you are under 18, please do not give us any personal information. In the event that we learn that we have inadvertently collected personal information from a child under age 18, we will delete that information as quickly as possible. If you believe that we might have any information from a child under 18, you can contact us by writing, emailing, calling, or submitting a chat. Please visit < a href="www.nuestrowallet.com" style={{color:'rgb(232,0,120)'}}>www.nuestrowallet.com</a> Contact Us section of the website for the information.</p>
        <h4 style={{padding:'1% 0'}}>9. Links to Other Web Sites and Services</h4>
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>The Services may contain links to and from third-party websites of our business partners, advertisers, and social media sites and our users may post links to third-party websites. If you follow a link to any of these websites, please note that these websites have their own privacy policies and that we do not accept any responsibility or liability for their policies. We strongly recommend that you read their privacy policies and terms and conditions of use to understand how they collect, use, and share information. We are not responsible for the privacy practices or the content on the websites of third-party sites.</p>
        <h4 style={{padding:'1% 0'}}>10. California Privacy Rights</h4>
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>Pursuant to Section 1798.83 of the California Civil Code, California residents have the right to obtain certain information about the types of personal information that companies with whom they have an established business relationship (and that are not otherwise exempt) have shared with third parties for direct marketing purposes during the preceding calendar year, including the names and addresses of those third parties, and examples of the types of services or products marketed by those third parties. If you wish to submit a request pursuant to Section 1798.83, please contact us by writing, email, calling, or submitting an in app chat. Please visit < a href="www.nuestrowallet.com" style={{color:'rgb(232,0,120)'}}>www.nuestrowallet.com</a> and the Contact Us section of the website for the information. </p>
        <p style={{fontFamily: 'Avenir', fontSize: 'small', color:'#ffffff'}}>California Privacy Rights</p>
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>If you are a resident of California, you have the following choices in our use and disclosure of your Personal Data subject to certain limitations under the California Consumer Privacy Act (“CCPA”):</p>
        <ul style={{padding:'0 4%'}}>
            <li style={{padding:'0.5% 0', fontSize: '18px', fontFamily: 'Avenir', listStyleType: 'disc'}}>Right to know. You may request, up to twice in a 12-month period, the following information about the Personal Data we have collected, used, disclosed or sold about you during the past 12 months: 
                <ul style={{padding:'0 6%'}}>
                    <li style={{padding:'0.5% 0', fontSize: '18px', fontFamily: 'Avenir', listStyleType:'circle'}}>the categories and specific pieces of Personal Data we have collected about you;</li>
                    <li style={{padding:'0.5% 0', fontSize: '18px', fontFamily: 'Avenir', listStyleType:'circle'}}>the categories of sources from which we collected the Personal Data;</li>
                    <li style={{padding:'0.5% 0', fontSize: '18px', fontFamily: 'Avenir', listStyleType:'circle'}}>the business or commercial purpose for which we collected the Personal Data;</li>
                    <li style={{padding:'0.5% 0', fontSize: '18px', fontFamily: 'Avenir', listStyleType:'circle'}}>the categories of third parties with whom we shared the Personal Data; and</li>
                    <li style={{padding:'0.5% 0', fontSize: '18px', fontFamily: 'Avenir', listStyleType:'circle'}}>the categories of Personal Data about you that we disclosed for a business purpose and sold to third parties, and the categories of third parties to whom the information was disclosed or sold.</li>
                </ul>
            </li>
            <li style={{padding:'0.5% 0', fontSize: '18px', fontFamily: 'Avenir', listStyleType: 'disc'}}>Right to delete. You may request that we delete the Personal Data we have collected from you, subject to certain limitations under the CCPA.</li>
            <li style={{padding:'0.5% 0', fontSize: '18px', fontFamily: 'Avenir', listStyleType: 'disc'}}>Right to opt-out from sale of Personal Data. You have the right to opt-out of the sale of your Personal Data. We do not sell your Personal Data.</li>
            <li style={{padding:'0.5% 0', fontSize: '18px', fontFamily: 'Avenir', listStyleType: 'disc'}}>Non-discrimination. The CCPA provides that you may not be discriminated against for exercising these rights.</li>
        </ul>
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>To submit a request to exercise any of the rights described above, you may email us at <a href="contact@nuestro.info" style={{color:'rgb(232,0,120)'}}>contact@nuestro.info</a></p>
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>We will verify your identity before responding to your request by either verifying that the email address from which you send the request matches your email address that we have on file, or by requiring you to log into your Account.</p>
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>Consumer Request by an Authorized Agent</p>
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>If any authorized agent submits a consumer request under the CCPA on your behalf, we require the authorized agent to submit the following information so that we can confirm their authority to act on your behalf:</p>
        <ul style={{padding:'0 4%'}}>
            <li style={{padding:'0.5% 0', fontSize: '18px', fontFamily: 'Avenir', listStyleType: 'disc'}}>Evidence of authorization to act on behalf of the California consumer: (1) California Secretary of State authorization, (2) notarized written permission from the California consumer, or (3) power of attorney.</li>
            <li style={{padding:'0.5% 0', fontSize: '18px', fontFamily: 'Avenir', listStyleType: 'disc'}}>Evidence of identity of the California consumer: (1) first and last name, (2) email address, and (3) password.</li>
        </ul>
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>Pursuant to Section 1798.83 of the California Civil Code, residents of California have the right to obtain certain information about the types of Personal Data that companies with whom they have an established business relationship (and that are not otherwise exempt) have shared with third parties for direct marketing purposes during the preceding calendar year, including the names and addresses of those third parties, and examples of the types of services or products marketed by those third parties. If you wish to submit a request pursuant to Section 1798.83, please contact Nuestro Wallet via email at <a href="contact@nuestro.info" style={{color:'rgb(232,0,120)'}}>contact@nuestro.info</a> .</p>
        
        <h4 style={{padding:'1% 0'}}>11. How to Contact Us</h4>
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>If you have any questions about this Privacy Policy or the website, you can contact us by any of the following ways:</p>
        <ul style={{padding:'0 4%'}}>
            <li style={{padding:'0.5% 0', fontSize: '18px', fontFamily: 'Avenir', listStyleType: 'disc'}}>Email: <a style={{color:'rgb(232,0,120)'}} href="contact@nuestro.info">contact@nuestro.info</a></li>
            <li style={{padding:'0.5% 0', fontSize: '18px', fontFamily: 'Avenir', listStyleType: 'disc'}}>Phone: <a href="tel:1-844-800-6185" style={{color:'rgb(232,0,120)'}}>1-844-800-6185</a></li>
            <li style={{padding:'0.5% 0', fontSize: '18px', fontFamily: 'Avenir', listStyleType: 'disc'}}>Mail: 444 Metroplex Dr, Unit B-225, Nashville, TN 37211</li>
        </ul>

        <h4 style={{padding:'1% 0'}}>12. Changes to Our Privacy Policy</h4>
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>We may modify or update this Privacy Policy from time to time to reflect the changes in our business and practices, and so you should review this page periodically. When we change the policy in a material manner, we will let you know via email and update the ’last modified’ date at the top of this page. If you object to any changes, you may close your account. Continuing to use our Services after we publish changes to this Privacy Policy means that you are consenting to the changes.</p>
        <br/><br/>
        <div className="text-right"><i style={{fontSize: '12px', fontWeight: '300'}}>version 1.0</i></div>
    </section>
    <Footer/>
    </div>
  )
}

export default Legal_Four;