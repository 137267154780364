import React from 'react';
import card from '../../assets/images/card_screen.png';
import dashboard from '../../assets/images/dashboard_screen.png'
import account from '../../assets/images/account_screen.png'
import kyc from '../../assets/images/kyc_screen.png'
import launcher from '../../assets/images/launcher_screen.png';
import mobile_infographic from '../../assets/images/mobile-infographic.png'
import appstore from "../../assets/images/app_store_logo.webp";
import playstore from "../../assets/images/google_play_logo.webp";
import "../../styles/main.css";
import "../../styles/responsive.css";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../../node_modules/bootstrap/dist/js/bootstrap.bundle.min";
import Footer from '../../components/footer/english';
import NavBar from '../../components/navbar/english';

const MobileEn = () => {
    
    return (
        <div className="home page-template page-template-homepage page-template-homepage-php page page-id-6 logged-in admin-bar elementor-default elementor-kit-732 elementor-page elementor-page-6 dialog-body dialog-buttons-body dialog-container dialog-buttons-container customize-support" style={{
            backgroundImage: `url('../../assets/images/home_bg_scaled.jpg')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            height: '100%',
        }}>
            <NavBar />
            <section className='p-3'>
            <div className="col-12 d-sm-flex sm-flex-column">
        <div className="col-lg-6 col-sm-12">
            <img className="p-lg-5" src={mobile_infographic} width="600" />
        </div>
        <div className="col-lg-6 col-sm-12 pt-5 justify-content-center">
            <h4 style={{color: 'rgb(232, 0, 120)', fontFamily: 'Avenir',}} className="mt-lg-5 pt-lg-5">Nuestro Wallet</h4>
            <h1 style={{color:'rgb(33, 67, 136)', fontFamily: 'RockoUltraFLF',}}>Banking made easy, fun, smart.</h1>
            <h4 className="pt-2" style={{color: 'rgb(232, 0, 120)', fontFamily: 'Avenir'}}>Enjoy Anytime, Anywhere Banking with Nuestro Wallet</h4>      
            <div className="col-8 pl-0 mt-4 wow fadeInLeft animated d-lg-flex" data-wow-delay="4ms"
                        data-wow-duration="2000ms"
                        style={{visibility: 'visible', animationDuration: '2000ms', animationDelay: '4ms', animationName: 'fadeInLeft',}}>
                        <a href="https://play.google.com/store/apps/details?id=com.nuestro.app" target="_blank"><img
                                src={playstore}/></a>
                        <a href="https://apps.apple.com/app/nuestro/id1670035380" target="_blank"><img
                                src={appstore}/></a>
                    </div>
        </div>
    </div>

    <div className="col-12 d-sm-flex sm-flex-column">
        <div className="col-lg-4 col-sm-12 pt-5 mt-lg-5 pb-5 justify-content-center" style={{visibility: 'visible', animationDuration: '2000ms', animationDelay: '4ms', animationName: 'fadeInLeft',}}>
            <h1 style={{color:'rgb(33, 67, 136)', fontFamily: 'RockoUltraFLF',}} className="mt-lg-5 pt-lg-5">Sign up</h1>
            <h5 className="pt-2" style={{color: 'rgb(232, 0, 120)', fontFamily: 'Avenir'}}>Joining the Nuestro family is easy. All you need is a Passport or Social Security Number, followed by a document confirming your address. We are dedicated to serving the needs of our community and advancing equal opportunity</h5>      
        </div>
        <div className="col-lg-4 col-sm-12">
            <img className="p-lg-5 fadeInLeft" src={kyc} width="600" style={{visibility: 'visible', animationDuration: '2000ms', animationDelay: '4m', animationName: 'fadeInRight',}}/>
        </div>
        <div className="col-lg-4"></div>
    </div>

    <div className="col-12 d-lg-flex flex-lg-row-reverse">
        <div className="col-lg-4 col-sm-12 pt-5 mt-lg-5 pb-5 justify-content-center" style={{visibility: 'visible', animationDuration: '2000ms', animationDelay: '4m', animationName: 'fadeInRight',}}>
            <h1 style={{color:'rgb(33, 67, 136)', fontFamily: 'RockoUltraFLF',}} className="mt-lg-5 pt-lg-5">Application Dashboard</h1>
            <h5 className="pt-2" style={{color: 'rgb(232, 0, 120)', fontFamily: 'Avenir'}}>Welcome to your Dashboard! Here you can monitor your balance, manage your Nuestro cards, and take advantage of local rewards for instant cashback.</h5>      
        </div>
        <div className="col-lg-4 col-sm-12">
            <img className="p-lg-5 fadeInLeft" src={dashboard} width="600" style={{visibility: 'visible', animationDuration: '2000ms', animationDelay: '4ms', animationName: 'fadeInLeft',}}/>
        </div>
        <div className="col-lg-4"></div>
    </div>

    <div className="col-12 d-sm-flex sm-flex-column">
        <div className="col-lg-4 col-sm-12 pt-5 mt-lg-5 pb-5 justify-content-center" style={{visibility: 'visible', animationDuration: '2000ms', animationDelay: '4ms', animationName: 'fadeInLeft',}}>
            <h1 style={{color:'rgb(33, 67, 136)', fontFamily: 'RockoUltraFLF',}} className="mt-lg-5 pt-lg-5">Checking Account</h1>
            <h5 className="pt-2" style={{color: 'rgb(232, 0, 120)', fontFamily: 'Avenir'}}>Your account balance and transaction history are just a tap away. Enjoy the simplicity of managing your finances and detecting any suspicious activities with our Checking Account!</h5>      
        </div>
        <div className="col-lg-4 col-sm-12">
            <img className="p-lg-5 fadeInLeft" src={account} width="600" style={{visibility: 'visible', animationDuration: '2000ms', animationDelay: '4m', animationName: 'fadeInRight',}}/>
        </div>
        <div className="col-lg-4"></div>
    </div>

    <div className="col-12 d-lg-flex flex-lg-row-reverse">
        <div className="col-lg-4 col-sm-12 pt-5 mt-lg-5 pb-5 justify-content-center" style={{visibility: 'visible', animationDuration: '2000ms', animationDelay: '4m', animationName: 'fadeInRight',}}>
            <h1 style={{color:'rgb(33, 67, 136)', fontFamily: 'RockoUltraFLF',}} className="mt-lg-5 pt-lg-5">My Card</h1>
            <h5 className="pt-2" style={{color: 'rgb(232, 0, 120)', fontFamily: 'Avenir'}}>Crafted for convenience, your Nuestro card – physical or virtual – complements your lifestyle with effortless transactions.</h5>      
        </div>
        <div className="col-lg-4 col-sm-12">
            <img className="p-lg-5 fadeInLeft" src={card} width="600" style={{visibility: 'visible', animationDuration: '2000ms', animationDelay: '4ms', animationName: 'fadeInLeft',}}/>
        </div>
        <div className="col-lg-4"></div>
    </div>

    <div className="col-12 d-sm-flex sm-flex-column">
        <div className="col-lg-4 col-sm-12 pt-5 mt-lg-5 pb-5 justify-content-center" style={{visibility: 'visible', animationDuration: '2000ms', animationDelay: '4ms', animationName: 'fadeInLeft',}}>
            <h1 style={{color:'rgb(33, 67, 136)', fontFamily: 'RockoUltraFLF',}} className="mt-lg-5 pt-lg-5">Nuestro Launcher</h1>
            <h5 className="pt-2" style={{color: 'rgb(232, 0, 120)', fontFamily: 'Avenir'}}>Embrace ease with the Nuestro Launcher. The Launcher makes your financial journey smooth and efficient by providing quick access to add or send funds, instant access to bank statements, and managing or adding contacts via QR code.</h5>      
        </div>
        <div className="col-lg-4 col-sm-12">
            <img className="p-lg-5 fadeInLeft" src={launcher} width="600" style={{visibility: 'visible', animationDuration: '2000ms', animationDelay: '4m', animationName: 'fadeInRight',}}/>
        </div>
        <div className="col-lg-4"></div>
    </div>

            </section>
            <Footer />
        </div>
    )
}

export default MobileEn;