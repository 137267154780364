import React from 'react'
import "../../styles/main.css";
import "../../styles/responsive.css";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../../node_modules/bootstrap/dist/js/bootstrap.bundle.min";
import legal_docs from "../../assets/documents/Electronic_Communication_Consent_Nuestro.pdf"
import Footer from '../../components/footer/english';
import NavBar from '../../components/navbar/english';


const Legal_Three = () => {
  return (
    <div>
        <NavBar/>
        <section className="main-slider pt-5" style={{backgroundColor: '#014384', color:'#ffffff', padding:'0 8%', textAlign: 'justify'}}>
        
        <h1 style={{fontFamily: 'RockoUltraFLF'}} className="text-center">Electronic Communication Consent </h1><br/><br/>

        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>

  <p style={{fontFamily: 'Avenir', fontSize: '15px', color: '#ffffff', margin: '0'}}>Effective Date: October 31, 2023</p>

  <a href={legal_docs} 
     style={{textAlign: 'right', fontSize: '15px', color: '#ffffff', textDecoration: 'none', border:'2px solid rgb(232, 0, 120)', borderRadius:'20px', backgroundColor:'rgb(232, 0, 120)', padding:'8px 15px'}} 
     download="Electronic_Communication_Consent_Nuestro.pdf">
    Download pdf
  </a>

</div>

        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>We want to provide you with communications electronically. Certain laws and regulations require us to provide communications to you “in writing,” which means you may be entitled to receive the information on paper. The E-SIGN Act allows us to provide you communications electronically and to conduct transactions with you electronically, with your consent.</p>
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>Please read this Nuestro Wallet Electronic Communication Consent (the “Consent”) carefully prior to providing us with your consent. This Consent describes how we deliver and receive communications to and from you electronically and asks you to consent to use electronic records and signatures in our relationship with you. If you do not agree to this Consent or you later withdraw your consent provided herein, you may not be able to continue to use our services.</p>

        <h4 style={{padding:'1% 0'}}>Definitions</h4>
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>“<b>We</b>”, “<b>us</b>”, and “<b>our</b>” means <b>Nuestro Wallet</b>, its current or future affiliated companies, agents, assignees and service providers. </p>
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>“<b>You</b>” and “<b>your</b>” mean each applicant, account owner and anyone else with access to the account. If there is more than one owner, then these words mean each account owner separately, and all account owners jointly. </p>
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>“<b>Communication</b>” means each application, agreement, disclosure, notice, fee schedule, response to claims, statement, privacy policy, record, document and other information related to your account or to any Product, or that you sign, submit or agree to at our request. </p>
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>“<b>Product</b>” means each and every account, product or service we offer or will offer</p>
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>The words “include” and “including,” when used at the beginning of a list of one or more items, indicates that the list contains examples and is not exclusive or exhaustive, and the items in the list are only illustrations.</p>

        <h4 style={{padding:'1% 0'}}>Scope of Communications to be Provided in Electronic Form</h4>
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>This Consent applies to all Communications and Products. By applying for or using a Product, you agree that any Communications will be provided in electronic format, to the extent allowed by law, and that paper Communications will not be sent. Your consent to receive electronic Communications and transactions includes, but is not limited to:</p>
        <ul style={{padding:'0 4%'}}>
            <li style={{padding:'0.5% 0', fontSize: '18px', fontFamily: 'Avenir', listStyleType: 'disc'}}>All legal and regulatory disclosures and communications associated with the Product;</li>
            <li style={{padding:'0.5% 0', fontSize: '18px', fontFamily: 'Avenir', listStyleType: 'disc'}}>The Account Terms, including any amendments thereto, and any and all agreements by and between you and us that relate to a Product;</li>
            <li style={{padding:'0.5% 0', fontSize: '18px', fontFamily: 'Avenir', listStyleType: 'disc'}}>Privacy policies and notices;</li>
            <li style={{padding:'0.5% 0', fontSize: '18px', fontFamily: 'Avenir', listStyleType: 'disc'}}>Responses to claims filed in connection with a Product;</li>
            <li style={{padding:'0.5% 0', fontSize: '18px', fontFamily: 'Avenir', listStyleType: 'disc'}}>Statements; and</li>
            <li style={{padding:'0.5% 0', fontSize: '18px', fontFamily: 'Avenir', listStyleType: 'disc'}}>All other communications between us and your concerning the Product and any related transactions, products or services.</li>
        </ul>

        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>Sometimes the law, or our agreement with you, requires you to give us a written notice. You must still provide these notices to us on paper, unless we specifically tell you in another Communication how you may deliver that notice to us electronically.</p>
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>We reserve the right, at our sole discretion, to discontinue the provision of electronic Communications, or to terminate or change the terms and conditions upon which electronic Communications are provided. We will provide you with notice of any such termination or change as required by law.</p>
        <h4 style={{padding:'1% 0'}}>Method of Providing Communications in Electronic Form</h4>
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>All Communications that we provide in electronic form will be provided either (1) by e-mail or (2) by access to a website designated in an email notice from us, (3) through the any mobile application we may make available, (4) to the extent permitted by law, by access to a website generally designated in advance for such purpose, or (5) in the manner specified in any other agreement we or our affiliates have with you. </p>
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>If you seek to obtain a new product, service or account with us, we may remind you that you have already consented to receiving electronic Communications and using electronic signatures in your relationship with us.</p>
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>Continuing to use our Products after receiving updates to our system requirements signifies your acceptance of the change and reaffirmation of your consent.</p>
        <h4 style={{padding:'1% 0'}}>Keeping your Records Current</h4>
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>It is your responsibility to provide us with a true, accurate and complete e-mail address, street address, and other information related to this Consent and a Product, and to maintain and update promptly any changes in this information. You can update information (such as your e-mail address) by contacting us at <a href="mailto:Contact@nuestro.info" style={{color:'rgb(232,0,120)'}}>Contact@nuestro.info</a> . We are not responsible for any delay or failure in the receipt of the Communications if we send the Communications to the last e-mail address you provided to us.</p>
        <h4 style={{padding:'1% 0'}}>System Requirements for Accessing Communications</h4>
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>In order to access, view, and retain electronic Communications that we make available, you must have:</p>
        <ul style={{padding:'0 4%'}}>
            <li style={{padding:'0.5% 0', fontSize: '18px', fontFamily: 'Avenir', listStyleType: 'disc'}}>computers capable of running one of these compatible browsers:</li>
            <li style={{padding:'0.5% 0', fontSize: '18px', fontFamily: 'Avenir', listStyleType: 'disc'}}>Internet Explorer version 9.0 or higher.</li>
            <li style={{padding:'0.5% 0', fontSize: '18px', fontFamily: 'Avenir', listStyleType: 'disc'}}>Firefox version 35 or higher.</li>
            <li style={{padding:'0.5% 0', fontSize: '18px', fontFamily: 'Avenir', listStyleType: 'disc'}}>Safari version 6.1 or higher.</li>
            <li style={{padding:'0.5% 0', fontSize: '18px', fontFamily: 'Avenir', listStyleType: 'disc'}}>Chrome version 38 or higher.</li>
            <b>OR</b>
            <li style={{padding:'0.5% 0', fontSize: '18px', fontFamily: 'Avenir', listStyleType: 'disc'}}>an Apple iPhone or iPad running iOS version 9.0 or higher.</li>
            <li style={{padding:'0.5% 0', fontSize: '18px', fontFamily: 'Avenir', listStyleType: 'disc'}}>access to an active e-mail account with an email service provider. </li>
        </ul>

        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>We may update these requirements as necessary to preserve the ability to receive electronic Communications. If there is a substantial change in these requirements, you will be notified as required by law.</p>
        <h4 style={{padding:'1% 0'}}>Requesting Paper Copies</h4>
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>We will not send paper copies of any Communication; however, we reserve the right, but assume no obligation, to provide a paper (instead of electronic) copy of any Communication that you have authorized us to provide electronically. You can obtain a paper copy of an electronic Communication by printing it or by requesting that we mail a paper copy. To request a paper copy, call us at <a href="tel:1-844-800-6185" style={{color:'rgb(232,0,120)'}}>1-844-800-6185</a> during normal business hours, except for national holidays. There may be a fee associated with the request for the delivery of paper copies of any Communication provided electronically pursuant to this Consent.</p>
        <h4 style={{padding:'1% 0'}}>Communications in Writing</h4>
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>All Communications in either electronic or paper format from us to you will be considered “in writing.” You should print or download a copy of this Consent and any other Communications for your records.</p>
        <h4 style={{padding:'1% 0'}}>Withdrawing Your Consent</h4>
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>You can withdraw your consent to receive Communications electronically at any time. Your withdrawal of consent will become effective after we have had a reasonable opportunity to act upon it. To withdraw your consent to receive Communications electronically, you must contact us by e-mailing at <a href="contact@nuestro.info" style={{color:'rgb(232,0,120)'}}>contact@nuestro.info</a>.</p>
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>If you withdraw consent, your access to all Products will be terminated and any account you have with us will be closed and funds will be returned to you in accordance with the account agreement. If you withdraw consent, the legal validity and enforceability of prior Communications delivered in electronic form will not be affected.</p>
        <h4 style={{padding:'1% 0'}}>Consent</h4>
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>By applying for or using a Product or by checking any call to action (including “I Agree” or similar language), you give us affirmative consent to receive electronic Communications as described herein. </p>
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>By providing your consent, you are also confirming that you have the hardware and software described above, that you are able to receive and review electronic Communications, and that you have an active email account. You are also confirming that you are authorized to, and do, consent on behalf of all the other account owners, authorized signers, authorized representatives, delegates, product owners and/or service users identified with your Products.</p>
        <br/><br/>
        <div className="text-right"><i style={{fontSize: '12px', fontWeight: '300'}}>version 1.0</i></div>
    </section>
    <Footer/>
    </div>
  )
}

export default Legal_Three;