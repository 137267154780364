import React, {useState, useContext} from "react";
import { Routes, Route } from 'react-router-dom';
import CommingSoonEn from "./pages/comming_soon/english";
import HomeEn from "./pages/home/english";
import LoginScreen from "./pages/login/english"
import HomeEs from "./pages/home/spanish";
import MobileEn from "./pages/mobile/english";
import MobileEs from "./pages/mobile/spanish";
import AboutEn from "./pages/about/english";
import AboutEs from "./pages/about/spanish";
import ContactEn from "./pages/contact/english";
import ContactEs from "./pages/contact/spanish";
import ProductEn from "./pages/product/english";
import ProductEs from "./pages/product/spanish";
import LegalEn from "./pages/legal/english";
import LegalEs from "./pages/legal/spanish";
import Legal_One from "./pages/legal_documents/business_account_and_cardholder_agreement";
import Legal_Two from "./pages/legal_documents/customer_account_and _cardholder_agreement";
import Legal_Three from "./pages/legal_documents/electronic_communication_consent";
import Legal_Four from "./pages/legal_documents/privacy_policy";
import Legal_Five from "./pages/legal_documents/terms_of_services";
import Protected from "./Protected";
import authContext from "./authContext";

// class App extends React.Component {
const App = () => {
  
  const [authenticated, setAuthenticated] = useState(JSON.parse(localStorage.getItem("authenticated")));

  
  // render = () => {
    return (
      <authContext.Provider value={{ authenticated, setAuthenticated }}>
        <Routes>
          <Route path="/" element={<CommingSoonEn />} />
          <Route path="/login" element={<LoginScreen />} />
          <Route path="/home" element={<Protected isLoggedIn={authenticated}><HomeEn /></Protected>} />
          {/* <Route path="/es/home" element={<Protected isLoggedIn={authenticated}><HomeEs /></Protected>} /> */}
          <Route path="/mobile" element={<Protected isLoggedIn={authenticated}><MobileEn /></Protected>} />
          {/* <Route path="/es/mobile" element={<Protected isLoggedIn={authenticated}><MobileEs /></Protected>} /> */}
          <Route path="/aboutus" element={<Protected isLoggedIn={authenticated}><AboutEn /></Protected>} />
          {/* <Route path="/es/aboutus" element={<Protected isLoggedIn={authenticated}><AboutEs /></Protected>} /> */}
          <Route path="/contactus" element={<Protected isLoggedIn={authenticated}><ContactEn /></Protected>} />
          {/* <Route path="/es/contactus" element={<Protected isLoggedIn={authenticated}><ContactEs /></Protected>} /> */}
          <Route path="/product" element={<Protected isLoggedIn={authenticated}><ProductEn /></Protected>} />
          {/* <Route path="/es/product" element={<Protected isLoggedIn={authenticated}><ProductEs /></Protected>} /> */}
          <Route path="/legal" element={<Protected isLoggedIn={authenticated}><LegalEn /></Protected>} />
          {/* <Route path="/es/legal" element={<Protected isLoggedIn={authenticated}><LegalEs /></Protected>} /> */}
          <Route path="/legal/nuestro_business_account_and_cardholder_agreement" element={<Protected isLoggedIn={authenticated}><Legal_One /></Protected>} />
          <Route path="/legal/nuestro_customer_account_and_cardholder_agreement" element={<Protected isLoggedIn={authenticated}><Legal_Two /></Protected>} />
          <Route path="/legal/electronic_communication_consent" element={<Protected isLoggedIn={authenticated}><Legal_Three /></Protected>} />
          <Route path="/legal/privacy_policy" element={<Protected isLoggedIn={authenticated}><Legal_Four /></Protected>} />
          <Route path="/legal/terms_of_services" element={<Protected isLoggedIn={authenticated}><Legal_Five /></Protected>} />
        </Routes>
        </authContext.Provider>  
    );
  }
// }

export default App;
