import React from 'react'
import '../../styles/main.css';
import '../../styles/responsive.css';
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../../node_modules/bootstrap/dist/js/bootstrap.bundle.min";
import tiktok from "../../assets/icons/tiktok.webp";
import instagram from "../../assets/icons/instagram.webp";
import facebook from "../../assets/icons/facebook.webp";
import youtube from "../../assets/icons/youtube.webp";
import twitter from "../../assets/icons/twitter.webp";

const FooterEn = () => {
  return (
    <>
    <footer className="main-footer">

<div className="container">
    <div className="widgets-section">
        <div className="row clearfix align-items-center">

            <div className="footer-column col-lg-4 col-md-6 col-sm-12">
                <div className="footer-widget services-widget">

                    <ul className="footer-service-list">
                        <li><a target="_blank"
                                href="/legal/privacy_policy" style={{textDecoration:'none'}}>Privacy Policy</a></li>
                    </ul>

                </div>
            </div>

            <div className="big-column col-lg-5 col-md-12 col-sm-12">
                <ul className="social-links">
                    <li><a href="https://www.tiktok.com/@nuestrowallet" target="_blank" rel="noopener noreferrer"><img alt="tiktok_logo" src={tiktok} /></a>
                    </li>
                    <li><a href="https://www.instagram.com/nuestrowallet" target="_blank" rel="noopener noreferrer"><img alt="instagram_logo" src={instagram}/></a>
                    </li>
                    <li><a href="https://www.facebook.com/nuestrowallet/" target="_blank" rel="noopener noreferrer"><img alt="facebook_logo" src={facebook}/></a>
                    </li>
                    <li><a href="https://twitter.com/NuestroWallet" target="_blank" rel="noopener noreferrer"><img alt="twitter_logo" src={twitter}/></a>
                    </li>
                    <li><a href="https://www.youtube.com/channel/UCLoqN-UhpkP1qZEyIDCdUbw" target="_blank" rel="noopener noreferrer"><img alt="youtube_logo" src={youtube}/></a>
                    </li>
                </ul>
            </div>

            <div className="footer-bottom col-lg-3 col-md-12 col-sm-12">
                <div className="clearfix">
                    <div className="text-center">
                        <div className="copyright mb-3">© 2023 Nuestro Financial LLC</div>
                        <div className="copyright mb-3">Nuestro is not a bank. Nuestro is a financial technology
                            company. Banking services provided by Mbanq’s Bank partners.
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

</footer>
    </>
  )
}

export default FooterEn;