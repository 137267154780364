import React from 'react'
import "../../styles/main.css";
import "../../styles/responsive.css";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../../node_modules/bootstrap/dist/js/bootstrap.bundle.min";
import FooterEn from '../../components/footer/english';
import NavBarEn from '../../components/navbar/english';
import proslider_2 from '../../assets/images/prod_slider_2_card_2.png';
import about_us from "../../assets/images/about_us_banner.png";

const AboutEn = () => {
  return (
    <div>
        <NavBarEn/>
            <section className="main-slider pt-5">
        <div className="container">
            <div className="flex-sm-row-reverse row">
                <div className="col-lg-6 col-sm-6 pt-5">
                    <div className="right_slide">
                        <div className="globe_about wow fadeInRight animated" data-wow-delay="7ms" data-wow-duration="1500ms"
                            style={{visibility: "visible", animationDuration: "1500ms", animationDelay: "7ms", animationName: "fadeInRight"}}>
                            <img src={proslider_2}/>
                        </div>
                        <div className="wow fadeInLeft animated" data-wow-delay="8ms" data-wow-duration="1500ms"
                            style={{visibility: "visible", animationDuration: "1500ms", animationDelay: "8ms", animationName: "fadeInLeft"}}>
                            <img src={about_us} style={{width:"100%"}} alt="about us image"/>
                        </div>                     
                    </div>
                </div>
                <div className="col-lg-6 col-sm-12" style={{zIndex: '-1', fontFamily: 'RockoUltraFLF'}}>
                    <h1 style={{fontFamily: 'RockoUltraFLF', color:"#1A428A", fontSize: "60px", fontWeight: "600"}}>Where <span style={{ color:'rgb(232,0,120)'}}>Tradition</span> Meets <span style={{color:'rgb(232,0,120)'}}>Innovation</span></h1>
                    <div className="banner_below" >
                        <p style={{color:"#ffffff"}}>At Nuestro, we want to be a trusted brand, and Nuestro Wallet carries this goal forward into the digital world. By combining the best of banking traditions with the latest technological innovations, we provide a secure and efficient space for all your financial needs. Our Wallet is more than a platform; it's a community where your finances thrive.</p>
                    </div>
                </div>

            </div>
        </div>

    </section>
    <FooterEn/>
    </div>
  )
}

export default AboutEn;