import React from 'react'
import "../../styles/main.css";
import "../../styles/responsive.css";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../../node_modules/bootstrap/dist/js/bootstrap.bundle.min";
import Footer from '../../components/footer/english';
import NavBar from '../../components/navbar/english';
import proslider_2 from '../../assets/images/prod_slider_2_card_2.png';
import contactus from '../../assets/images/contactus.png';
import telephone from '../../assets/images/telephone.png';
import email from '../../assets/images/email.png';

const ContactEn = () => {
  return (
    <div>
        <NavBar/>
        <section className="main-slider pt-5">
        <div className="container">
            <div className="row">
                <div className="col-lg-6 col-sm-6">
                    <div className="right_slide">
                        <div className="globe wow fadeInRight animated" data-wow-delay="7ms" data-wow-duration="1500ms"
                            style={{visibility: 'visible', animationDuration: '1500ms', animationDelay: '7ms', animationName: 'fadeInRight'}}>
                            <img src={proslider_2}/>
                        </div>
                        <div className="wow fadeInLeft animated" data-wow-delay="8ms" data-wow-duration="1500ms"
                            style={{visibility: 'visible', animationDuration: '1500ms', animationDelay: '8ms', animationName: 'fadeInLeft', transform: 'scaleX(-1)'}}>
                            <img src={contactus}
                                style={{width:'100%'}}/>
                        </div>                     
                    </div>
                </div>
                <div className="col-lg-6 col-sm-12">
                    <p style={{fontFamily: 'RockoUltraFLF', color:'#1A428A', fontSize: '60px', fontWeight: '600'}}>Get In Touch!</p>
                    <h3 style={{fontFamily: 'Avenir', color:'rgb(232,0,120)', padding: '20px 0 20px 10px', fontWeight: '600'}}>Our Support Desk</h3>
                    <img src={telephone} width="8%" style={{padding: '20px 0 20px 20px'}}/><a href="tel:+1(615)-800-6181" style={{fontFamily: 'RockoUltraFLF', fontSize: '20px'}}>  +1(615)-800-6181</a>
                    <br/>
                    <p style={{padding:'0 0 0 60px', fontFamily: 'Avenir', fontSize: '18px', color:'#1A428A', fontWeight: '600'}}>&#8226; 9:00 am - 5:00 pm CDT/CST</p>
                    <img src={telephone} width="8%" style={{padding: '10px 0 10px 20px'}}/><a href="tel:+1(844)-800-6185" style={{fontFamily: 'RockoUltraFLF', fontSize: '20px'}}>  +1(844)-800-6185</a>
                    <br/>
                    <p style={{padding:'0 0 0 60px', fontFamily: 'Avenir', fontSize: '18px', color:'#1A428A', fontWeight: '600'}}>&#8226; 9:00 am - 5:00 pm CDT/CST</p>
                    <img src={email} width="8%" style={{padding: '10px 0 10px 20px'}}/><a href="mailto:contact@nuestro.info" style={{fontFamily: 'RockoUltraFLF', fontSize: '20px'}}>  contact@nuestro.info</a>
                </div>

            </div>
        </div>

    </section>
    <Footer/>
    </div>
  )
}

export default ContactEn;