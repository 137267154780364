import React, { useState, useContext, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import { ReactComponent as IconFacebook } from '../../assets/icons/facebook.svg';
import { ReactComponent as IconTwitter } from '../../assets/icons/twitter.svg';
import { ReactComponent as IconInstagram} from '../../assets/icons/instagram.svg';
import { ReactComponent as IconTiktok} from '../../assets/icons/tiktok.svg';
import { ReactComponent as IconYoutube} from '../../assets/icons/youtube.svg';
import NuestroWallet from '../../assets/icons/nuestro_wallet_logo.webp';
import "../../styles/login.css";
import authContext from "../../authContext";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";




const LoginEn = () => {
  const navigate = useNavigate();
  const { setAuthenticated } = useContext(authContext);
  const [errmessage, setErrorMessage] = useState([])
  const [recaptchaValue, setRecaptchaValue] = useState(null);

  const [inputs, setInputs] = useState({});

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };


  useEffect(() => {
    console.log("Inside login useEffect")
  }, []);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}))
  }

  const handleLoginSubmit = (event) => {
    event.preventDefault();
    console.log(inputs);
    setErrorMessage("")

    if (!recaptchaValue) {
      setErrorMessage("Please complete the reCAPTCHA verification.");
      return;
    }
    if (Object.keys(inputs).length === 0) {
      setErrorMessage("All fields are required!")
      return
    } 
    if(inputs["username"] === undefined || inputs.username === ""){
      setErrorMessage("Email is required!")
      return
    }
    if(inputs["password"] === undefined || inputs.password === ""){
      setErrorMessage("Password is required!")
      return
    }
    
    axios.post(
      process.env.REACT_APP_AUTH_URL,
      inputs,
      {
        headers: {
          'content-type': 'application/json'
        }
      }
    ).then(response => {
      console.log("response :: ", response)
      if(response && response.data){
        if(response.data.status_code && response.data.status_code === 200){
          if(response.data.access_token){
            setAuthenticated(true);
            localStorage.setItem("authenticated",true)
            navigate("/home");
            // Check if the viewport width is less than or equal to 768 (you can adjust this threshold)
            // if (window.innerWidth <= 768) {
            //   navigate('/mobile');
            // } else {
            //   navigate('/home');
            // }
          } 
        }else{
          if(response.data.error_description){
            setErrorMessage(response.data.error_description)
          }
        }
      } 
    })
    .catch(err => console.warn(err));
    
  }

    return (
      <div className="card">
        <div className="header">
          <div className="logo">
            <a href="."><img src={NuestroWallet} alt="nuestro logo" width="250"/></a>
          </div>
          <div>
          </div>
        </div>
        <div className="content">
          <div className="title-holder">
            <h1>Login</h1>
            <p style={{color:'red'}}>{errmessage?errmessage:''}</p>
          </div>
          <div className='loginForm'>
            <form onSubmit={handleLoginSubmit}>
            <div className="form-field" style={{margin:10}}>
              <input type="email" name='username' value={inputs.username  || ""} placeholder='Email' onChange={handleChange} />
            </div>
            <div className="form-field" style={{margin:10}}>
              <input type='password' name='password' value={inputs.password || ""} placeholder='Password' onChange={handleChange} />
            </div>
            <div className="form-field" style={{ margin: 20 }}>
            <ReCAPTCHA
              sitekey="6LcxNRUpAAAAAJ10d7WNPHqJrmhNgJPk6jMeF8iF"
              onChange={handleRecaptchaChange}
            />
          </div>
            <div className="form-field" style={{margin:10}}>
              <button type="Submit" className='btn' >Login</button>
            </div>
            </form>
          </div>
          
        </div>
        <div className="footer">
        <div className="social">
        <a href="https://www.tiktok.com/@nuestrowallet" title="Facebook" target="_blank" rel="noopener noreferrer">
              <IconTiktok className="icon" />
            </a>
            <a href="https://www.facebook.com/nuestrowallet/" title="Facebook" target="_blank" rel="noopener noreferrer">
              <IconFacebook className="icon" />
            </a>
            <a href="https://twitter.com/NuestroWallet" title="Twitter" target="_blank" rel="noopener noreferrer">
              <IconTwitter className="icon" />
            </a>
            <a href="https://www.instagram.com/nuestrowallet" title="GitHub" target="_blank" rel="noopener noreferrer">
              <IconInstagram className="icon" />
            </a>
            <a href="https://www.youtube.com/channel/UCLoqN-UhpkP1qZEyIDCdUbw" title="GitHub" target="_blank" rel="noopener noreferrer">
              <IconYoutube className="icon" />
            </a>
          </div>
        </div>
      </div>
    );
  }


export default LoginEn;