import React from 'react'
import "../../styles/main.css";
import "../../styles/responsive.css";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../../node_modules/bootstrap/dist/js/bootstrap.bundle.min";
import Footer from '../../components/footer/english';
import NavBar from '../../components/navbar/english';

const LegalEn = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <NavBar/>
        <div className="container container-wrapper" style={{ flex: 1 }}>
            
            
                
                
            <h1 className="mt-3 mb-3 legal-title text-center" style={{fontFamily: 'RockoUltraFLF', color:'rgb(232, 0, 120)',}}>Legal</h1>
<div className="legal">
<a href="/legal/privacy_policy" target="_blank" rel="noopener"  style={{textDecoration:'none'}}>Privacy Policy </a><br/>    
<a href="/legal/electronic_communication_consent" target="_blank" rel="noopener"  style={{textDecoration:'none'}}>Electronic communication consent </a><br/>
<a href="/legal/terms_of_services" target="_blank" rel="noopener"  style={{textDecoration:'none'}}>Nuestro terms of services </a><br/>
<a href="/legal/nuestro_business_account_and_cardholder_agreement" target="_blank" rel="noopener"  style={{textDecoration:'none'}}>Nuestro business account and cardholder agreement</a><br/>
<a href="/legal/nuestro_customer_account_and_cardholder_agreement" target="_blank" rel="noopener"  style={{textDecoration:'none'}}>Nuestro customer account and cardholder agreement</a></div>
    </div>
    <Footer/>
    </div>
  )
}

export default LegalEn;