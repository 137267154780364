import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import "../../styles/main.css";
import "../../styles/responsive.css";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../../node_modules/bootstrap/dist/js/bootstrap.bundle.min";
import Footer from '../../components/footer/english';
import NavBar from '../../components/navbar/english';
import proslider_1 from '../../assets/images/prod_slider_1.webp';
import proslider_2 from '../../assets/images/prod_slider_2.webp';
import proslider_2_2 from '../../assets/images/prod_slider_2_card_2.png';
import proslider_card from '../../assets/images/prod_slider_2_card_opt.webp';
import slide1 from '../../assets/images/prod_slider_2_card_opt.webp';
import right_arrow from '../../assets/images/right-arrow.png';
import left_arrow from '../../assets/images/left-arrow.png';
import NW1 from '../../assets/images/NW-4-8.png';
import NW2 from '../../assets/images/NW-5-8.png';
import NW3 from '../../assets/images/NW-6-8.png';

const ProductEn = () => {
  return (
    <div>
        <NavBar/>
        <section className="slider container mb-3">
　　　        <Carousel interval={3000} wrap={true} className='slide' prevIcon={<img src={left_arrow} alt="Previous" style={{ color: 'red', width:'20px' }} />} nextIcon={<img src={right_arrow} alt="Next" style={{ color: 'blue', width:'20px' }} />} indicators>
　　　      <Carousel.Item className='slide'>
　　　        <section className="main-slider">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-5" style={{zIndex:'-1',}}>
                                                <div className="wow fadeInLeft animated" data-wow-delay="0ms"
                                                    data-wow-duration="1500ms"
                                                    style={{visibility: 'visible', animationDuration: '1500ms', animationDelay: '0ms', animationName: 'fadeInLeft'}}>
                                                    <h2 className="prod_banertext" style={{textAlign: 'left', fontFamily: 'RockoUltraFLF'}}>Business <br/><span
                                                            style={{color:'rgb(232, 0, 120)', fontFamily: 'RockoUltraFLF'}}>Checking</span></h2>

                                                            <div
                                                    data-wow-delay="2ms" data-wow-duration="1500ms"
                                                    style={{visibility: 'visible', animationDuration: '1500ms', animationDelay: '100ms', animationName: 'fadeInLeft'}}>
                                                    <div className="prod_i1" style={{fontFamily: 'RockoUltraFLF'}}>Visa Business Debit Card</div>
                                                </div>
                                                <div
                                                    data-wow-delay="2ms" data-wow-duration="1500ms"
                                                    style={{visibility: 'visible', animationDuration: '1500ms', animationDelay: '100ms', animationName: 'fadeInLeft'}}>
                                                    <div className="prod_i1" style={{fontFamily: 'RockoUltraFLF'}}>$0 Monthly Fees</div>
                                                </div>
                                                <div
                                                    data-wow-delay="2ms" data-wow-duration="1500ms"
                                                    style={{visibility: 'visible', animationDuration: '1500ms', animationDelay: '100ms', animationName: 'fadeInLeft'}}>
                                                    <div className="prod_i1" style={{fontFamily: 'RockoUltraFLF'}}>English/Spanish Customer Support</div>
                                                </div>
                                                </div>
                                                <br/>
                                                <br/>
                                                <br/><br/>
                                            </div>

                                            <div className="col-7">
                                                <div className="right_slide">
                                                    <div className="prod_2_globe wow animated" data-wow-delay="7ms"
                                                        data-wow-duration="1500ms"
                                                        style={{visibility: 'visible', animationDuration: '1500ms', animationDelay: '7ms', animationName: 'fadeInTop'}}>
                                                        <img src={proslider_2} width="400" alt="slider card"/>
                                                    </div>
                                                    <div className="prod_2_midllepart wow animated" data-wow-delay="8ms"
                                                        data-wow-duration="1500ms"
                                                        style={{visibility: 'visible', animationDuration: '1500ms', animationDelay: '8ms', animationName: 'fadeInRight'}}>
                                                        <img src={proslider_1} width='55%' alt="slider card"/>
                                                     </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </section>
　　　      </Carousel.Item>
　　　      <Carousel.Item className='slide'>
　　　        <section className="main-slider">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-5" style={{zIndex:'-1',}}>
                                                <div className="wow fadeInLeft animated" data-wow-delay="0ms"
                                                    data-wow-duration="1500ms"
                                                    style={{visibility: 'visible', animationDuration: '1500ms', animationDelay: '0ms', animationName: 'fadeInLeft'}}>
                                                    <h2 className="prod_banertext" style={{textAlign: 'left', fontFamily: 'RockoUltraFLF'}}>Nuestro <br/><span
                                                            style={{color:"rgb(232, 0, 120)", fontFamily: 'RockoUltraFLF'}}>Card</span></h2>

                                                            <div
                                                    data-wow-delay="2ms" data-wow-duration="1500ms"
                                                    style={{visibility: 'visible', animationDuration: '1500ms', animationDelay: '100ms', animationName: 'fadeInLeft'}}>
                                                    <div className="prod_i1" style={{fontFamily: 'RockoUltraFLF'}}>Visa Debit Card</div>
                                                </div>
                                                <div
                                                    data-wow-delay="2ms" data-wow-duration="1500ms"
                                                    style={{visibility: 'visible', animationDuration: '1500ms', animationDelay: '100ms', animationName: 'fadeInLeft'}}>
                                                    <div className="prod_i1" style={{fontFamily: 'RockoUltraFLF'}}>$0 Monthly Fees</div>
                                                </div>
                                                <div
                                                    data-wow-delay="2ms" data-wow-duration="1500ms"
                                                    style={{visibility: 'visible', animationDuration: '1500ms', animationDelay: '100ms', animationName: 'fadeInLeft'}}>
                                                    <div className="prod_i1" style={{fontFamily: 'RockoUltraFLF'}}>Withdraw cash at eligible ATMs</div>
                                                </div>
                                                </div>

                                                
                                                {/* <div className=" wow fadeInLeft prod_hint"
                                                    style="color:rgb(33, 67, 136); "
                                                    data-wow-delay="1ms" data-wow-duration="1500ms">**No Debit check
                                                    when applying and to enroll in the program
                                                    <br/>
                                                    **APR increases are
                                                    determined on a per-user basis and based on behavior analytics once
                                                    credit line exceeds the self-secured amount 

                                                </div>  */}
                                            </div>

                                            <div className="col-7">
                                                <div className="right_slide">
                                                    <div className="prod_globe wow animated" data-wow-delay="7ms"
                                                        data-wow-duration="1500ms"
                                                        style={{visibility: 'visible', animationDuration: '1500ms', animationDelay: '7ms', animationName: 'fadeInLeft'}}>
                                                        <img src={proslider_2_2} width="400" alt="slider card"/>
                                                    </div>
                                                    <div className="prod_midllepart wow animated" data-wow-delay="8ms"
                                                        data-wow-duration="1500ms"
                                                        style={{visibility: 'visible', animationDuration: '1500ms', animationDelay: '8ms', animationName: 'fadeInRight'}}>
                                                        <img src={proslider_card} width="100%" alt="slider card"/>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </section>
　　　      </Carousel.Item>
　　　      <Carousel.Item className='slide'>
　　　        <section className="main-slider">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-5" style={{zIndex:'-1',}}>
                                                <div className="wow fadeInLeft animated" data-wow-delay="0ms"
                                                    data-wow-duration="1500ms"
                                                    style={{visibility: 'visible', animationDuration: '1500ms', animationDelay: '0ms', animationName: 'fadeInLeft'}}>
                                                    <h2 className="prod_banertext" style={{textAlign: 'left', fontFamily: 'RockoUltraFLF'}}>Banking<br/><span
                                                            style={{color:'rgb(232, 0, 120)', fontFamily: 'RockoUltraFLF'}}>Account</span></h2>

                                                            <div
                                                    data-wow-delay="2ms" data-wow-duration="1500ms"
                                                    style={{visibility: 'visible', animationDuration: '1500ms', animationDelay: '100ms', animationName: 'fadeInLeft'}}>
                                                    <div className="prod_i1" style={{fontFamily: 'RockoUltraFLF'}}>Full Banking Experience.</div>
                                                </div>
                                                <div
                                                    data-wow-delay="2ms" data-wow-duration="1500ms"
                                                    style={{visibility: 'visible', animationDuration: '1500ms', animationDelay: '100ms', animationName: 'fadeInLeft'}}>
                                                    <div className="prod_i1" style={{fontFamily: 'RockoUltraFLF'}}>Deposit, Transfers, and more</div>
                                                </div>
                                                 {/* <div
                                                    data-wow-delay="2ms" data-wow-duration="1500ms"
                                                    style="visibility: visible; animation-duration: 1500ms; animation-delay: 100ms; animation-name: fadeInLeft;">
                                                    <div className="prod_i1" style="font-family: 'RockoUltraFLF';">English/Spanish Customer Support</div>
                                                </div>  */}
                                                </div>
                                                <br/>
                                                <br/>
                                                <br/><br/>
                                            </div>

                                            <div className="col-7" style={{zIndex:'100'}}>
                                                <div className="right_slide">
                                                    <div className="prod_2_globe wow animated" data-wow-delay="7ms"
                                                        data-wow-duration="1500ms"
                                                        style={{visibility: 'visible', animationDuration: '1500ms', animationDelay: '7ms', animationName: 'fadeInTop'}}>
                                                        <img src={proslider_2} width="400" alt="slider card"/>
                                                    </div>
                                                    <div className="prod_2_midllepart wow animated" data-wow-delay="8ms"
                                                        data-wow-duration="1500ms"
                                                        style={{visibility: 'visible', animationDuration: '1500ms', animationDelay: '8ms', animationName: 'fadeInRight'}}>
                                                        <img src={proslider_1} width="55%" alt="slider card"/>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </section>
　　　      </Carousel.Item>
　　　    </Carousel>
　　　    </section>

    <section className="gradients bradient">
        <div className="container">
            <div className="row wow zoomIn animated" style={{visibility: 'visible', animationName: 'zoomIn'}}>
                <div className="col-lg-4" style={{paddingTop: '13.5px'}}>
                    <div className="padindle">
                        <img src={NW1}/>
                    </div>
                    <div className="yelsel">
                        <h1>SECURE</h1>
                        <p>We use the technology to keep you, your data, and your money<br/>
                            safe at all times. Just like we do out there, we are always looking out for our community.
                        </p>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="padindle">
                        <img src={NW2}/>
                    </div>
                    <div className="yelsel">
                        <h1>SAFE</h1>
                        <p>Learn how to bank, save, and manage your spending without fear of spiraling mistakes. We are
                            you, we understand you. We got you.</p>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="padindle">
                        <img src={NW3}/>
                    </div>
                    <div className="yelsel">
                        <h1>EASY</h1>
                        <p>Our products are designed for you to learn quick, get things done and go on with your day. We
                            are all busy, we want to help you get it done RAPIDO!</p>
                    </div>
                </div>
            </div>

        </div>
    </section>
    <Footer/>
    </div>
  )
}

export default ProductEn;