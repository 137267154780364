import React from 'react';
import { ReactComponent as IconFacebook } from '../../assets/icons/facebook.svg';
import { ReactComponent as IconTwitter } from '../../assets/icons/twitter.svg';
import { ReactComponent as IconInstagram} from '../../assets/icons/instagram.svg';
import { ReactComponent as IconTiktok} from '../../assets/icons/tiktok.svg';
import { ReactComponent as IconYoutube} from '../../assets/icons/youtube.svg';
import Login from '../../assets/icons/login.png';
import NuestroWallet from '../../assets/icons/nuestro_wallet_logo.webp';
import "../../styles/commingsoon.css";

const CommingSoonEn = () => {
    return (
      <div className="card">
        <div className="header">
          <div className="logo">
            <a href="."><img src={NuestroWallet} alt="nuestro logo" width="250"/></a>
          </div>
          <div>
          <a href="login">
            <div className="login"><img src={Login} width="50"/></div>
          </a>
          </div>
        </div>
        <div className="content">
          <div className="title-holder">
            <h1>Get ready for the change.</h1>
            <p>Website coming soon. Please check back to know more. Shoot us an email if you're curious.</p>
          </div>
          <a href="mailto:contact@nuestro.info">
            <div className="cta">Send us an email</div>
          </a>
        </div>
        <div className="footer">
        <div className="social">
        <a href="https://www.tiktok.com/@nuestrowallet" title="Facebook" target="_blank" rel="noopener noreferrer">
              <IconTiktok className="icon" />
            </a>
            <a href="https://www.facebook.com/nuestrowallet/" title="Facebook" target="_blank" rel="noopener noreferrer">
              <IconFacebook className="icon" />
            </a>
            <a href="https://twitter.com/NuestroWallet" title="Twitter" target="_blank" rel="noopener noreferrer">
              <IconTwitter className="icon" />
            </a>
            <a href="https://www.instagram.com/nuestrowallet" title="GitHub" target="_blank" rel="noopener noreferrer">
              <IconInstagram className="icon" />
            </a>
            <a href="https://www.youtube.com/channel/UCLoqN-UhpkP1qZEyIDCdUbw" title="GitHub" target="_blank" rel="noopener noreferrer">
              <IconYoutube className="icon" />
            </a>
          </div>
        </div>
      </div>
    );
  }


export default CommingSoonEn;